<template>
  <section
    class="section space-y-6 lg:space-y-10"
    :class="sectionBackgroundClass(background)"
  >
    <h2
      v-if="titleEnabled"
      class="as-h1 container"
    >
      {{ title }}
    </h2>

    <UiLoader
      class="container"
      :has-data="!!(articles.length)"
      :loading="loading"
      empty-message="Er is geen nieuws gevonden."
    >
      <UiSwiper>
        <UiSwiperSlide
          v-for="article in articles"
          :key="article!.slug"
        >
          <NewsCard
            class="h-full"
            :article="article"
          />
        </UiSwiperSlide>
      </UiSwiper>
    </UiLoader>

    <div class="container flex justify-center lg:justify-end">
      <NuxtLink
        class="btn btn-xs btn-secondary lg:btn-sm"
        to="/nieuws"
      >
        Bekijk al het nieuws
      </NuxtLink>
    </div>
  </section>
</template>

<script lang="ts" setup>
import {useArticlesQuery} from '@graphql/graphql';
import type {SectionProps} from '~/types/section';

defineProps<SectionProps>();

const {result, loading} = useArticlesQuery();

const articles = computed(() => result.value?.articles?.data ?? []);
</script>
